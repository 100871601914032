import React, { useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";

export const ExpandingButton = ({
  url,
  children,
  color,
  bgColor,
  hoverColor,
  hoverBGColor,
  notLink = false,
  customClass = "relative inline-block text-sm sm:text-base md:text-lg lg:text-xl transition-all font-medium px-4 py-2 self-end expanding-button",
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return notLink ? (
    <div
      className={`${customClass}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        backgroundColor: isHovered ? hoverBGColor : bgColor,
        color: isHovered ? hoverColor : color,
        border: `2px solid ${isHovered ? hoverBGColor : color}`,
        paddingLeft: isHovered ? "30px" : "24px",
        paddingRight: isHovered ? "50px" : "24px",
      }}
      {...props}
    >
      <span className="relative whitespace-nowrap">
        {children}
        <span
          className="small-arrow absolute top-1/2 right-[-30px] transform -translate-y-1/2 opacity-0 transition-opacity"
          style={{ opacity: isHovered ? 1 : 0 }}
        >
          <FiArrowUpRight strokeWidth="3" />
        </span>
      </span>
    </div>
  ) : (
    <a
      href={url}
      className={`${customClass}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        backgroundColor: isHovered ? hoverBGColor : bgColor,
        color: isHovered ? hoverColor : color,
        border: `2px solid ${isHovered ? hoverBGColor : color}`,
        paddingLeft: isHovered ? "30px" : "24px",
        paddingRight: isHovered ? "50px" : "24px",
      }}
      {...props}
    >
      <span className="relative whitespace-nowrap">
        {children}
        <span
          className="small-arrow absolute top-1/2 right-[-30px] transform -translate-y-1/2 opacity-0 transition-opacity"
          style={{ opacity: isHovered ? 1 : 0 }}
        >
          <FiArrowUpRight strokeWidth="3" />
        </span>
      </span>
    </a>
  );
};

export default ExpandingButton;
